import React, { useState, useRef, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "components/Modal";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import api from "services/api";
import { toast } from "react-toastify";
import AuthService from "services/auth";
import { GoSearch } from "react-icons/go";
import styles from "./styles.module.scss";
import LoadingIndicator from "components/LoadingIndicator";
import AsyncSelect from "react-select/async";

const showOptions = [25, 50, 100];

const InviteRequest = (props) => {
  const { className, showInvite, onClose } = props;
  const userData = AuthService.getUserData();
  const [loading, setLoading] = useState(false);

  const [invite, setInvite] = useState(null);
  const [inviteRequired, setInviteRequired] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e3e9ef",
    }),
  };

  const onInviteBlur = () => {};

  const onInviteChange = (selectedInvite) => {
    setInvite(selectedInvite);
  };

  const loadInviteOptions = async (inputText, callback) => {
    try {
      const res = await api.get(`/api/v1/regions?name=${inputText}`);
      callback(
        res.data.results.map((item) => ({
          value: `${item.pk}`,
          label: `${item.name}`,
        }))
      );
    } catch (e) {
      console.log("register error", e);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (!invite) {
      return;
    }
    try {
      const res = await api.post(
        `/api/v1/region/${invite.value}/invite/request`,
        { invitee: userData.email }
      );
      toast.success("Invite sent Successfully!");
    } catch (e) {
      console.log("register error", e);
    }
    setInvite(null);
    onClose();
  };

  const handleModelClose = () => {
    setInvite(null);
    onClose();
  };

  return (
    <>
      <Modal
        visible={showInvite}
        close={() => onClose()}
        disableOutsideClose={true}
        extraClass={styles.inviteWrapper}
      >
        <div className={styles.container}>
          <div className={styles.close} onClick={handleModelClose}>
            X
          </div>
          <form className={styles.inviteForm} onSubmit={submitForm}>
            <div className={styles.title}>
              <h4>Request Invite</h4>
            </div>
            <div className={styles.nameWrapper}>
              <div className={styles.inputWrapper}>
                <div className={styles.inputField}>
                  <label htmlFor="region" className="inputLabel">
                    Request Invite to Region
                  </label>
                  <div className={styles.inviteField}>
                    <AsyncSelect
                      className={styles.selectField}
                      value={invite}
                      styles={customStyles}
                      onChange={onInviteChange}
                      placeholder={""}
                      loadOptions={loadInviteOptions}
                      isClearable={true}
                      onBlur={onInviteBlur}
                      noOptionsMessage={() => null}
                    />
                    <button type="submit" className={"primary-btn"}>
                      Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default InviteRequest;
