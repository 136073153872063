import { Routes, Route, Navigate } from "react-router-dom";

import AuthService from "services/auth";
import MainLayout from "components/MainLayout";
import AuthLayout from "components/AuthLayout";

import Login from "./Login";
import Register from "./Register";
import Analysis from "./Analysis";
import Dashboard from "./Dashboard";
// import Help from "./Help";
import Inventory from "./Inventory";
import Idm from "./Idm";
import Settings from "./Settings";
import Upload from "./Upload";

const AuthRoutes = () => (
  <Routes>
    <Route path="/auth" element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route index element={<Navigate to="login" replace />} />
      <Route path="*" element={<Navigate to="login" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/auth/login" replace />} />
  </Routes>
);

const UserRoutes = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/analysis" element={<Analysis />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="idm" element={<Idm />} />
      {/*<Route path="help" element={<Help />} />*/}
      <Route index element={<Navigate to="dashboard" replace />} />
      <Route path="*" element={<Navigate to="dashboard" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/dashboard" replace />} />
  </Routes>
);

const Router = () => {
  const isLoggedIn = AuthService.isLoggedIn();

  if (!isLoggedIn) {
    return <AuthRoutes />;
  }

  return <UserRoutes />;
};

export default Router;
