import React from "react";

import styles from "./styles.module.scss";

const LoadingIndicator = (props) => {
  const { pageLoading, light, ...rest } = props;

  return (
    <div className={pageLoading ? styles["page-loader"] : ""}>
      <div
        className={`${styles.loading} ${light ? styles.light : ""}`}
        {...rest}
      ></div>
    </div>
  );
};

export default LoadingIndicator;
