import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { AiOutlineUser } from "react-icons/ai";
import AuthService from "services/auth";
import { AiOutlineDelete } from "react-icons/ai";
import api from "services/api";
import styles from "./styles.module.scss";
import LoadingIndicator from "components/LoadingIndicator";
import { toast } from "react-toastify";

const showOptions = [25, 50, 100];

const Table = (props) => {
  const { className, region, refresh } = props;
  const userData = AuthService.getUserData();

  const columns = [
    {
      label: "Photo",
      field: "photo",
    },
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Region",
      field: "region",
    },
    {
      label: "Type",
      field: "accountType",
    },
    {
      label: "Action",
      field: "action",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);

  // Pagination
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handlePageClick = async (event) => {
    setApiData(
      await getDataFromAPI(
        `/api/v1/region/${region}/members?limit=${rowsPerPage}&offset=${
          rowsPerPage * event.selected
        }`
      )
    );
  };

  const makeAdmin = async (event, item) => {
    try {
      await api.post(`/api/v1/account/${item.pk}/admin/add`);
      toast.success("Member is Now Admin");
    } catch (e) {
      console.log("register error", e);
    }
    setApiData(await getDataFromAPI(`/api/v1/region/${region}/members`));
  };

  const removeAdmin = async (event, item) => {
    try {
      await api.delete(`/api/v1/account/${item.pk}/admin/remove`);
      toast.success("Member is No Longer Admin");
    } catch (e) {
      console.log("register error", e);
    }
    setApiData(await getDataFromAPI(`/api/v1/region/${region}/members`));
  };

  const removeMember = async (event, item) => {
    try {
      await api.delete(`/api/v1/region/${region}/member/${item.pk}/remove`);
      toast.success("Member Removed");
    } catch (e) {
      console.log("register error", e);
    }
    setApiData(await getDataFromAPI(`/api/v1/region/${region}/members`));
  };

  const getDataFromAPI = async (url) => {
    setLoading(true);
    let dataList = [];
    try {
      const res = await api.get(url);
      const count = res.data.count;
      setPageCount(
        Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1)
      );
      for (let result of res.data.results) {
        const row = {
          pk: `${result.pk}`,
          photo: result.photo,
          name: `${result.first_name} ${result.last_name}`,
          region: `${result.region.name}`,
          accountType: result.role === "admin" ? "Admin" : "Member",
          role: `${result.role}`,
          email: `${result.email}`,
        };
        dataList.push(row);
      }
    } catch (e) {
      console.log("register error", e);
    }
    setLoading(false);
    return dataList;
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI(`/api/v1/region/${region}/members`));
    };
    fetchData();
  }, [refresh]);

  const visibleRows = useMemo(() => {
    return apiData || [];
  }, [apiData]);

  return (
    <>
      {loading && <LoadingIndicator light pageLoading />}
      <div className={styles.tableWrapper}>
        <div className={styles.header}>
          <div>Members</div>
        </div>
        <div className={classNames(styles.tableContainer, className)}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th
                    key={col.label}
                    style={{
                      textAlign: col.textAlign || "left",
                      width: col.width || "unset",
                    }}
                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {visibleRows.map((item, index) => (
                <tr key={index}>
                  {columns.map((col, i) => (
                    <td
                      key={i}
                      style={{
                        textAlign: col.textAlign || "left",
                        width: col.width || "unset",
                      }}
                    >
                      {col.field === "action" && userData?.role === "admin" ? (
                        <div className={styles.actions}>
                          {item.role === "normal" && (
                            <button
                              className={"primary-btn-sm"}
                              onClick={(e) => makeAdmin(e, item)}
                            >
                              Make Admin
                            </button>
                          )}
                          {item.role === "admin" &&
                            userData?.email !== item.email && (
                              <button
                                className={"primary-btn-sm"}
                                onClick={(e) => removeAdmin(e, item)}
                              >
                                Remove Admin
                              </button>
                            )}
                          {userData?.email !== item.email && (
                            <div
                              className={styles.remove}
                              onClick={(e) => removeMember(e, item)}
                            >
                              <AiOutlineDelete />
                            </div>
                          )}
                        </div>
                      ) : col.field === "photo" && item.photo !== null ? (
                        <img src={item[col.field]} width="35px" alt="" />
                      ) : col.field === "photo" && item.photo === null ? (
                        <div className={styles.iconWrapper}>
                          <AiOutlineUser />
                        </div>
                      ) : (
                        <span>{item[col.field]}</span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.footer}>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination"
          />

          <div className="dropdown">
            <span>Show: </span>
            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {showOptions.map((option) => (
                <option key={option} value={option}>
                  {option} rows
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
