import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { debounce } from "lodash";
import moment from "moment";
import api from "services/api";
import { GoSearch } from "react-icons/go";
import styles from "./styles.module.scss";
import LoadingIndicator from "components/LoadingIndicator";
import Findings from "components/Findings";
import { AiOutlineEye } from "react-icons/ai";

const showOptions = [25, 50, 100];

const Analyses = (props) => {
  const { className, refresh, leftMenu } = props;

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [orderStr, setOrderStr] = useState("");
  const [regionStr, setRegionStr] = useState("");
  const [manufacturerStr, setManufacturerStr] = useState("");
  const [region, setRegion] = useState({});
  const [regions, setRegions] = useState([]);

  const [showFinding, setShowFinding] = useState(false);

  const [analysis, setAnalysis] = useState(null);

  // Pagination
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const menuItems = [
    {
      label: "View",
      icon: AiOutlineEye,
    },
  ];

  const manufacturer = [
    {
      value: "",
      label: "Manufacturer",
    },
    {
      value: "tridium",
      label: "Tridium",
    },
    {
      value: "jci-metasys",
      label: "JCI Metasys",
    },
  ];

  const columns = [
    {
      label: "Date of Last Upload",
      field: "uploadedAt",
    },
    {
      label: "Manufacturer",
      field: "vender",
    },
    {
      label: "Region",
      field: "region",
    },
    {
      label: "Building",
      field: "building",
    },
    {
      label: "Point of Contact",
      field: "poc",
    },
    {
      label: "FRCS",
      field: "frcs",
    },
    {
      label: "System Name",
      field: "system",
    },
    {
      label: "Nomenclature",
      field: "nomenclature",
    },
    {
      label: "eMass Id",
      field: "emassId",
    },
    {
      label: "Uploaded By",
      field: "uploadedBy",
    },
  ];

  const sortOptions = [
    {
      label: "Sort by",
      field: "",
    },
    ...columns,
  ];

  const loadRegions = async () => {
    try {
      const res = await api.get("/api/v1/region/user/regions/dropdown");
      return res.data;
    } catch (e) {
      console.log("register error", e);
    }
  };

  const fetchRegions = async () => {
    setRegion(await getUserRegion());
    setRegions(await loadRegions());
  };

  const getUserRegion = async () => {
    try {
      const res = await api.get("/api/v1/region/user/region");
      return res.data;
    } catch (e) {
      console.log("register error", e);
    }
  };

  const setUserRegion = async (regionPk) => {
    try {
      const res = await api.post("/api/v1/region/user/region", {
        pk: regionPk,
      });
      return res.data;
    } catch (e) {
      console.log("register error", e);
    }
  };

  const onFindingClose = () => {
    setShowFinding(false);
    setAnalysis(null);
  };

  const onMenuClick = (item, menu) => {
    setAnalysis(item);
    setShowFinding(true);
  };

  const handlePageClick = async (event) => {
    setPage(event.selected);
    setApiData(
      await getDataFromAPI(
        `/api/v1/analyses?name=${searchStr}&region=${regionStr}&order=${orderStr}&limit=${rowsPerPage}&offset=${
          rowsPerPage * page
        }`
      )
    );
  };

  const getDataFromAPI = async (url) => {
    setLoading(true);
    let dataList = [];
    try {
      const res = await api.get(url);
      const count = res.data.count;
      setPageCount(
        Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1)
      );
      for (let result of res.data.results) {
        dataList.push({
          pk: `${result.pk}`,
          risk: `${result.risk_level}`,
          status: `${result.status}`,
          uploadedAt: moment(Date.parse(`${result.uploaded_at}`)).format(
            "L h:mm a"
          ),
          vender: result.vender ? `${result.vender}` : null,
          region: result.device?.region ? `${result.device.region.name}` : null,
          building: result.device?.building
            ? `${result.device.building}`
            : null,
          poc: result.device?.poc ? `${result.device.poc}` : null,
          frcs: result.device?.frcs ? `${result.device.frcs.name}` : null,
          system: result.device?.system ? `${result.device.system.name}` : null,
          nomenclature: result.device?.nomenclature
            ? `${result.device.nomenclature}`
            : null,
          emassId: result.device?.emass_id ? `${result.device.emass_id}` : null,
          uploadedBy: `${result.user.first_name} ${result.user.last_name}`,
        });
      }
    } catch (e) {
      console.log("register error", e);
    }
    setLoading(false);
    return dataList;
  };

  const handleSearchInput = debounce(async (e) => {
    const query = e.target.value;
    setSearchStr(query);
    setApiData(
      await getDataFromAPI(
        `/api/v1/analyses?name=${query}&region=${regionStr}&order=${orderStr}&manufacturer=${manufacturerStr}`
      )
    );
  }, 500);

  const handleOrderInput = async (e) => {
    const order = e.target.value;
    setOrderStr(order);
    setApiData(
      await getDataFromAPI(
        `/api/v1/analyses?name=${searchStr}&region=${regionStr}&order=${order}&manufacturer=${manufacturerStr}`
      )
    );
  };

  const handleManufacturerInput = async (e) => {
    const manufacturer = e.target.value;
    setManufacturerStr(manufacturer);
    setApiData(
      await getDataFromAPI(
        `/api/v1/analyses?name=${searchStr}&region=${regionStr}&order=${orderStr}&manufacturer=${manufacturer}`
      )
    );
  };

  const handleRegionInput = async (e) => {
    const regionPk = e.target.value;
    setRegion(await setUserRegion(regionPk));
    setRegionStr(regionPk.toString());
    setApiData(
      await getDataFromAPI(
        `/api/v1/analyses?name=${searchStr}&region=${regionPk}&order=${orderStr}&manufacturer=${manufacturerStr}`
      )
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI("/api/v1/analyses"));
    };
    if (refresh === true) {
      fetchData();
    } else {
      fetchRegions();
    }
  }, [refresh]);

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI("/api/v1/analyses"));
    };
    fetchData();
  }, []);

  const visibleRows = useMemo(() => {
    return loading ? [] : apiData || [];
  }, [page, rowsPerPage, apiData, loading]);

  const renderSingleMenu = (item) => {
    const menu = menuItems[0];
    const Icon = menu.icon;
    return (
      <td className={classNames(styles.actions, item.risk)}>
        <div
          className={styles.singleAction}
          onClick={() => onMenuClick(item, menu)}
        >
          {Icon && <Icon />}
          <span>{menu.label}</span>
        </div>
      </td>
    );
  };

  const renderMenuItems = (item, index) => {
    return (
      <>
        {menuItems?.length > 1 && (
          <td
            className={styles.actions}
            style={
              leftMenu && item.color
                ? { borderLeft: `8px solid ${item.color}` }
                : undefined
            }
          >
            <div className={styles.more}>
              <div className={`${styles.menus}`}>
                {menuItems.map((menu, index) => {
                  const Icon = menu.icon;
                  return (
                    <div
                      key={index}
                      className={styles.item}
                      onClick={() => onMenuClick(item, menu)}
                    >
                      {Icon && <Icon />}
                      {menu.label}
                    </div>
                  );
                })}
              </div>
            </div>
          </td>
        )}
        {menuItems?.length === 1 && renderSingleMenu(item)}
      </>
    );
  };

  return (
    <>
      {loading && <LoadingIndicator light pageLoading />}
      <div className={styles.tableWrapper}>
        <div className={styles.header}>
          <div className={styles.search}>
            <GoSearch />
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchInput}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.dropdownWrapper}>
            <div className="dropdown">
              <select
                className={styles.regionSelect}
                name="region"
                id="region"
                onChange={handleRegionInput}
              >
                {regions.map((item) => (
                  <option
                    value={item.pk}
                    key={item.pk}
                    selected={region && item.pk === region.pk}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="dropdown">
              <select
                onChange={handleManufacturerInput}
                className={styles.manufacturerSelect}
              >
                {manufacturer.map((item) => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={manufacturerStr && item.pk === manufacturerStr}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="dropdown">
              <select
                onChange={handleOrderInput}
                className={styles.orderSelect}
              >
                {sortOptions.map((col) => (
                  <option value={col.field} key={col.field}>
                    {col.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className={classNames(styles.tableContainer, className)}>
          <table className={styles.table}>
            <thead>
              <tr>
                {leftMenu && menuItems && <th></th>}
                {columns.map((col) => (
                  <th
                    key={col.label}
                    style={{
                      textAlign: col.textAlign || "left",
                      width: col.width || "unset",
                    }}
                  >
                    {col.label}
                  </th>
                ))}
                {!leftMenu && menuItems && <th></th>}
              </tr>
            </thead>
            <tbody>
              {visibleRows.map((item, index) => (
                <tr key={index}>
                  {leftMenu && menuItems && renderMenuItems(item, index)}
                  {columns.map((col, i) => (
                    <td
                      key={i}
                      style={{
                        borderLeft:
                          i === 0 ? `8px solid ${item.color}` : "unset",
                        textAlign: col.textAlign || "left",
                        width: col.width || "unset",
                      }}
                    >
                      <span>{item[col.field]}</span>
                    </td>
                  ))}
                  {!leftMenu && menuItems && renderMenuItems(item, index)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.footer}>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>
          <div className="dropdown">
            <span>Show: </span>
            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {showOptions.map((option) => (
                <option key={option} value={option}>
                  {option} rows
                </option>
              ))}
            </select>
          </div>
        </div>
        <Findings
          showFinding={showFinding}
          analysis={analysis}
          onClose={onFindingClose}
        />
      </div>
    </>
  );
};

export default Analyses;
