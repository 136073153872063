import React from "react";

import Analyses from "components/Analyses";

import styles from "./styles.module.scss";

const Analysis = () => {
  return (
    <div className={styles.container}>
      <Analyses className={styles.tableWrapper} leftMenu />
    </div>
  );
};

export default Analysis;
