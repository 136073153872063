import React from "react";

import styles from "./styles.module.scss";

const DashboardCard = ({
  title,
  data,
  color = "#202945",
  lastColWidth = "50%",
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.card}>
        {data.map((item, index) => (
          <div
            className={styles.item}
            key={index}
            style={
              index === 0
                ? {
                    borderBottom: `2px solid ${color}`,
                  }
                : {}
            }
          >
            <div className={styles.label}>{item.label}</div>
            <div className={styles.value} style={{ width: lastColWidth }}>
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardCard;
