import React, { useRef, useState, useEffect } from "react";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import classNames from "classnames";
import styles from "./styles.module.scss";
import api from "services/api";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import LoadingIndicator from "components/LoadingIndicator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";

const deviceFormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  poc: Yup.string().required("Required"),
  building: Yup.string().required("Required"),
  nomenclature: Yup.string().required("Required"),
  emass_id: Yup.string(),
});

const Device = (props) => {
  const { analysis, showDevice, onClose, disableOutsideClose, create } = props;

  // Fields
  const [device, setDevice] = useState(null);
  const [devicePk, setDevicePk] = useState(null);
  const [changeDevice, setChangeDevice] = useState(null);
  const [region, setRegion] = useState(null);
  const [frcs, setFrcs] = useState(null);
  const [system, setSystem] = useState(null);

  // Required Fields
  const [regionRequired, setRegionRequired] = useState(false);
  const [frcsRequired, setFrcsRequired] = useState(false);
  const [systemRequired, setSystemRequired] = useState(false);

  // Select Fields
  const [defaultDeviceOptions, setDefaultDeviceOptions] = useState([]);
  const [defaultRegionOptions, setDefaultRegionOptions] = useState([]);
  const [defaultFrcsOptions, setDefaultFrcsOptions] = useState([]);
  const [defaultSystemOptions, setDefaultSystemOptions] = useState([]);

  const [systemDisabled, setSystemDisabled] = useState(true);

  const bodyRef = useRef();

  useOutsideAlerter(bodyRef, disableOutsideClose ? null : onClose);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e3e9ef",
    }),
  };

  const clearFields = async () => {
    setChangeDevice(null);
    setDevice(null);
    setRegionRequired(false);
    setFrcsRequired(false);
    setSystemRequired(false);
    setSystemDisabled(true);
    setRegion(null);
    setFrcs(null);
    setSystem(null);
  };

  const onRegionBlur = () => {
    if (region === null) {
      setRegionRequired(true);
    }
  };

  const onFrcsBlur = () => {
    if (frcs === null) {
      setFrcsRequired(true);
      setSystemDisabled(true);
    } else {
      fetchDefaultSystemOptions();
    }
  };

  const onSystemBlur = () => {
    if (system === null) {
      setSystemRequired(true);
    }
  };

  const onDeviceBlur = () => {};

  const submitDeviceForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    if (
      regionRequired === true ||
      frcsRequired === true ||
      systemRequired === true
    ) {
      return;
    }

    // Add the extra search field
    values.region = region.value;
    values.frcs = frcs.value;
    values.system = system.value;

    // When present, add or update analysis with existing device
    // Also update any changes made to the existing device.
    if (changeDevice) {
      try {
        let res = await api.put(
          `/api/v1/device/${changeDevice.value}/update`,
          values
        );
        if (res.status === 200) {
          await api.post(`/api/v1/analysis/${analysis.pk}/device/add`, {
            device: changeDevice.value,
          });
          // Display message for when we swapped out a device
          if (devicePk) {
            toast.success("Existing Device Swapped");
          } else {
            // Display a message for when we added an exsting device
            toast.success("Existing Device Attached");
          }
        }
        clearFields();
        setSubmitting(false);
        onClose(true);
      } catch (e) {
        console.log("register error", e);
      }
      // When present, update existing device
    } else if (devicePk) {
      try {
        await api.put(`/api/v1/device/${devicePk}/update`, values);
        toast.success("Device Updated");
        clearFields();
        setSubmitting(false);
        onClose(true);
      } catch (e) {
        console.log("register error", e);
      }
    } else {
      try {
        let res = await api.post("/api/v1/device/create", values);
        if (res.status === 201) {
          await api.post(`/api/v1/analysis/${analysis.pk}/device/add`, {
            device: res.data.pk,
          });
          toast.success("Device Attached");
        }
        clearFields();
        onClose(true);
      } catch (e) {
        console.log("register error", e);
      }
      setSubmitting(false);
    }
  };

  const onRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);
    setRegionRequired(false);
  };

  const onFrcsChange = (selectedFrcs) => {
    setFrcs(selectedFrcs);
    if (selectedFrcs === null) {
      setSystemDisabled(true);
    } else {
      setSystemDisabled(false);
      setFrcsRequired(false);
    }
  };

  const onSystemChange = (selectedSystem) => {
    setSystem(selectedSystem);
    setSystemRequired(false);
  };

  const onDeviceChange = async (selectedDevice, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      // Revert back to previous
      if (devicePk) {
        try {
          const res = await api.get(`/api/v1/device/${devicePk}/detail`);
          setRegion({ label: res.data.region.name, value: res.data.region.pk });
          setFrcs({ label: res.data.frcs.name, value: res.data.frcs.pk });
          setSystem({ label: res.data.system.name, value: res.data.system.pk });
          setChangeDevice(selectedDevice);
          setDevice(res.data);

          setRegionRequired(false);
          setFrcsRequired(false);
          setSystemRequired(false);
          setSystemDisabled(false);
        } catch (e) {
          console.log("register error", e);
        }
        // Clear out form
      } else {
        setRegion(null);
        setFrcs(null);
        setSystem(null);
        setChangeDevice(null);
        setDevice(null);

        setRegionRequired(false);
        setFrcsRequired(false);
        setSystemRequired(false);
        setSystemDisabled(false);
        return;
      }
    } else {
      try {
        const res = await api.get(
          `/api/v1/device/${selectedDevice.value}/detail`
        );
        setRegion({ label: res.data.region.name, value: res.data.region.pk });
        setFrcs({ label: res.data.frcs.name, value: res.data.frcs.pk });
        setSystem({ label: res.data.system.name, value: res.data.system.pk });
        setChangeDevice(selectedDevice);
        setDevice(res.data);

        setRegionRequired(false);
        setFrcsRequired(false);
        setSystemRequired(false);
        setSystemDisabled(false);
      } catch (e) {
        console.log("register error", e);
      }
    }
  };

  const fetchDefaultRegionOptions = () => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/v1/region/user/regions?limit=1000`);
        setDefaultRegionOptions(
          res.data.results.map((item) => ({
            label: item.name,
            value: item.pk,
          }))
        );
      } catch (e) {
        console.log("register error", e);
      }
    };
    fetchData();
  };

  const fetchDefaultFrcsOptions = () => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/v1/frcs?limit=1000`);
        setDefaultFrcsOptions(
          res.data.results.map((item) => ({
            label: item.name,
            value: item.pk,
          }))
        );
      } catch (e) {
        console.log("register error", e);
      }
    };
    fetchData();
  };

  const fetchDefaultSystemOptions = () => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          `/api/v1/frcs/${frcs.value}/system?limit=1000`
        );
        setDefaultSystemOptions(
          res.data.results.map((item) => ({
            label: item.name,
            value: item.pk,
          }))
        );
      } catch (e) {
        console.log("register error", e);
      }
    };
    fetchData();
  };

  const fetchDefaultDeviceOptions = () => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/v1/devices?limit=1000`);
        setDefaultDeviceOptions(
          res.data.results.map((item) => ({
            label: item.name,
            value: item.pk,
          }))
        );
      } catch (e) {
        console.log("register error", e);
      }
    };
    fetchData();
  };

  const loadRegionOptions = async (inputText, callback) => {
    try {
      const res = await api.get(
        `/api/v1/region/user/regions?name=${inputText}`
      );
      callback(
        res.data.results.map((item) => ({ label: item.name, value: item.pk }))
      );
    } catch (e) {
      console.log("register error", e);
    }
  };

  const loadFrcsOptions = async (inputText, callback) => {
    try {
      const res = await api.get(`/api/v1/frcs?name=${inputText}`);
      callback(
        res.data.results.map((item) => ({ label: item.name, value: item.pk }))
      );
    } catch (e) {
      console.log("register error", e);
    }
  };

  const loadSystemOptions = async (inputText, callback) => {
    try {
      const res = await api.get(
        `/api/v1/frcs/${frcs.value}/system?name=${inputText}`
      );
      callback(
        res.data.results.map((item) => ({ label: item.name, value: item.pk }))
      );
    } catch (e) {
      console.log("register error", e);
    }
  };

  const loadDeviceOptions = async (inputText, callback) => {
    try {
      const res = await api.get(`/api/v1/devices?name=${inputText}`);
      callback(
        res.data.results.map((item) => ({ label: item.name, value: item.pk }))
      );
    } catch (e) {
      console.log("register error", e);
    }
  };

  const handleModelClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (analysis && analysis.device) {
      setDevice(analysis.device);
      setRegion({
        label: analysis.device.region.name,
        value: analysis.device.region.pk,
      });
      setFrcs({
        label: analysis.device.frcs.name,
        value: analysis.device.frcs.pk,
      });
      setSystem({
        label: analysis.device.system.name,
        value: analysis.device.system.pk,
      });
      setRegionRequired(false);
      setFrcsRequired(false);
      setSystemRequired(false);
      setSystemDisabled(false);
      setDevicePk(analysis.device.pk);
    } else {
      setDevice(null);
      setChangeDevice(null);
      setRegion(null);
      setFrcs(null);
      setSystem(null);
      setRegionRequired(false);
      setFrcsRequired(false);
      setSystemRequired(false);
      setSystemDisabled(true);
      setDevicePk(null);
    }
    fetchDefaultRegionOptions();
    fetchDefaultFrcsOptions();
    fetchDefaultDeviceOptions();
  }, [analysis]);

  return (
    <>
      <Modal
        visible={showDevice}
        disableOutsideClose={true}
        close={() => clearFields()}
      >
        <div className={styles.close} onClick={handleModelClose}>
          X
        </div>
        <div className={styles.title}>
          <h4>
            {create ? <span>Add Device</span> : <span>Update Device</span>}
          </h4>
        </div>

        <div className={styles.nameWrapper}>
          <div className={styles.inputWrapper}>
            <div className={styles.inputField}>
              <label htmlFor="name" className="inputLabel">
                Existing Device
              </label>
              <AsyncSelect
                className={styles.selectField}
                value={changeDevice}
                styles={customStyles}
                onChange={onDeviceChange}
                placeholder={""}
                loadOptions={loadDeviceOptions}
                defaultOptions={defaultDeviceOptions}
                isClearable={true}
                onBlur={onDeviceBlur}
                noOptionsMessage={() => null}
              />
            </div>
            <div>Search for existing device</div>
            <div>OR</div>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            name: device && device.name ? device.name : "",
            building: device && device.building ? device.building : "",
            poc: device && device.poc ? device.poc : "",
            nomenclature:
              device && device.nomenclature ? device.nomenclature : "",
            emass_id: device && device.emass_id ? device.emass_id : "",
          }}
          validationSchema={deviceFormSchema}
          onSubmit={submitDeviceForm}
        >
          {({ isSubmitting }) => (
            <Form className={styles.deviceForm}>
              <div className={styles.nameWrapper}>
                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="name" className="inputLabel">
                      Device Name
                    </label>
                    <div>
                      <Field name="name" type="text" className="text-input" />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="inputError"
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="region" className="inputLabel">
                      Region
                    </label>
                    <div>
                      <AsyncSelect
                        className={styles.selectField}
                        value={region}
                        styles={customStyles}
                        onChange={onRegionChange}
                        placeholder={""}
                        loadOptions={loadRegionOptions}
                        defaultOptions={defaultRegionOptions}
                        isClearable={true}
                        onBlur={onRegionBlur}
                        noOptionsMessage={() => null}
                      />
                      {regionRequired === true ? (
                        <div className={"inputError"}>Required</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="building" className="inputLabel">
                      Building
                    </label>
                    <div>
                      <Field
                        name="building"
                        type="text"
                        className="text-input"
                      />
                      <ErrorMessage
                        name="building"
                        component="div"
                        className="inputError"
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="poc" className="inputLabel">
                      Point of Contact
                    </label>
                    <div>
                      <Field name="poc" type="text" className="text-input" />
                      <ErrorMessage
                        name="poc"
                        component="div"
                        className="inputError"
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="frcs" className="inputLabel">
                      FRCS
                    </label>
                    <div>
                      <AsyncSelect
                        className={styles.selectField}
                        value={frcs}
                        onChange={onFrcsChange}
                        placeholder={""}
                        loadOptions={loadFrcsOptions}
                        defaultOptions={defaultFrcsOptions}
                        isClearable={true}
                        onBlur={onFrcsBlur}
                        noOptionsMessage={() => null}
                      />
                      {frcsRequired === true ? (
                        <div className={"inputError"}>Required</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="frcs" className="inputLabel">
                      System
                    </label>
                    <div>
                      <AsyncSelect
                        className={styles.selectField}
                        value={system}
                        isDisabled={systemDisabled}
                        onChange={onSystemChange}
                        placeholder={""}
                        loadOptions={loadSystemOptions}
                        defaultOptions={defaultSystemOptions}
                        isClearable={true}
                        onBlur={onSystemBlur}
                        noOptionsMessage={() => null}
                      />
                      {systemRequired === true ? (
                        <div className={"inputError"}>Required</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="nomenclature" className="inputLabel">
                      Nomenclature
                    </label>
                    <div>
                      <Field
                        name="nomenclature"
                        type="text"
                        className="text-input"
                      />
                      <ErrorMessage
                        name="nomenclature"
                        component="div"
                        className="inputError"
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.inputWrapper}>
                  <div className={styles.inputField}>
                    <label htmlFor="emass_id" className="inputLabel">
                      eMass Id
                    </label>
                    <div>
                      <Field
                        name="emass_id"
                        type="text"
                        className="text-input"
                      />
                      <ErrorMessage
                        name="emass_id"
                        component="div"
                        className={"inputError"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.btnWrapper}>
                <button
                  type="submit"
                  className={classNames(styles.loginBtn, "primary-btn")}
                >
                  {create ? (
                    <span>
                      {isSubmitting ? <LoadingIndicator light /> : null}
                      Add Device
                    </span>
                  ) : (
                    <span>
                      {isSubmitting ? <LoadingIndicator light /> : null}
                      Update Device
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Device;
