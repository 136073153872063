import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Responsive, WidthProvider } from "react-grid-layout";
import api from "services/api";
import DashboardCard from "components/DashboardCard";

import styles from "./styles.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const ResponsiveGridLayout = WidthProvider(Responsive);

const mdLayout = [
  { i: "a", x: 0, y: 0, w: 1, h: 1 },
  { i: "b", x: 1, y: 0, w: 1, h: 1 },
  { i: "c", x: 2, y: 0, w: 1, h: 2 },
  { i: "d", x: 0, y: 1, w: 1, h: 1 },
  { i: "e", x: 1, y: 1, w: 1, h: 1 },
];

const smLayout = [
  { i: "a", x: 0, y: 0, w: 1, h: 1 },
  { i: "b", x: 1, y: 0, w: 1, h: 1 },
  { i: "c", x: 0, y: 1, w: 1, h: 2 },
  { i: "d", x: 1, y: 1, w: 1, h: 1 },
  { i: "e", x: 1, y: 1, w: 1, h: 1 },
];

const xsLayout = [
  { i: "a", x: 0, y: 0, w: 1, h: 1 },
  { i: "b", x: 0, y: 1, w: 1, h: 1 },
  { i: "c", x: 0, y: 2, w: 1, h: 2 },
  { i: "d", x: 0, y: 3, w: 1, h: 1 },
  { i: "e", x: 0, y: 4, w: 1, h: 1 },
];

const initialLayouts = {
  lg: mdLayout,
  md: mdLayout,
  sm: smLayout,
  xs: xsLayout,
};

const Dashboard = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [region, setRegion] = useState({});
  const [regions, setRegions] = useState([]);
  const [chartSeverity, setChartSeverity] = useState([]);
  const [cardOptions, setCardOptions] = useState([]);
  const [layouts, setLayouts] = useState(initialLayouts);

  const loadRegions = async () => {
    try {
      const res = await api.get("/api/v1/region/user/regions/dropdown");
      return res.data;
    } catch (e) {
      console.log("register error", e);
    }
  };

  const getUserRegion = async () => {
    try {
      const res = await api.get("/api/v1/region/user/region");
      return res.data;
    } catch (e) {
      console.log("register error", e);
    }
  };

  const setUserRegion = async (regionPk) => {
    try {
      const res = await api.post("/api/v1/region/user/region", {
        pk: regionPk,
      });
      return res.data;
    } catch (e) {
      console.log("register error", e);
    }
  };

  const handleRegionChange = async (event) => {
    const regionPk = event.target.value;
    setRegion(await setUserRegion(regionPk));
    if (regionPk) {
      fetchData(regionPk);
    } else {
      fetchData();
    }
  };

  const fetchRegions = async () => {
    setRegion(await getUserRegion());
    setRegions(await loadRegions());
  };

  const fetchData = async (region) => {
    let res;
    try {
      if (region !== undefined) {
        res = await api.get(`/api/v1/dashboard?region=${region}`);
      } else {
        res = await api.get("/api/v1/dashboard");
      }

      setChartSeverity([
        {
          datasets: [
            {
              data: [res.data.low, res.data.total_findings],
              backgroundColor: ["#2fc0c8", "#dae6f2"],
              borderWidth: 0,
            },
          ],
          label: "LOW",
        },
        {
          datasets: [
            {
              data: [res.data.medium, res.data.total_findings],
              backgroundColor: ["#fdd93e", "#dae6f2"],
              borderWidth: 0,
            },
          ],
          label: "MEDIUM",
        },
        {
          datasets: [
            {
              data: [res.data.high, res.data.total_findings],
              backgroundColor: ["#feab44", "#dae6f2"],
              borderWidth: 0,
            },
          ],
          label: "HIGH",
        },
        {
          datasets: [
            {
              data: [res.data.critical, res.data.total_findings],
              backgroundColor: ["#d60b20", "#dae6f2"],
              borderWidth: 0,
            },
          ],
          label: "CRITICAL",
        },
      ]);
      setCardOptions([
        {
          title: "Manufacturer Risk Scorecard",
          visible: true,
          color: "#dfb495",
          data: [
            ...[{ label: "Manufacturer", value: "# of Critical Severity" }],
            ...res.data.vender_criticals,
          ],
          gridIndex: "a",
        },
        {
          title: "POC Scorecard",
          visible: true,
          color: "#dfb495",
          data: [
            ...[{ label: "Point of Contact", value: "#" }],
            ...res.data.top_pocs,
          ],
          lastColWidth: "20%",
          gridIndex: "b",
        },
        {
          title: "Top 10 Findings",
          visible: true,
          color: "#dfb495",
          data: [
            ...[{ label: "Finding", value: "#" }],
            ...res.data.top_findings,
          ],
          lastColWidth: "20%",
          gridIndex: "c",
        },
        {
          title: "Critical Vulnerabilities by Base",
          visible: true,
          color: "#dfb495",
          data: [
            ...[{ label: "Base", value: "# of Critical Severity" }],
            ...res.data.base_criticals,
          ],
          gridIndex: "d",
        },
      ]);
    } catch (e) {
      console.log("register error", e);
    }
    setDataLoaded(true);
  };

  useEffect(() => {
    if (dataLoaded !== true) {
      fetchRegions();
      fetchData(region.pk);
    }

    const visibleIndexes = cardOptions
      .filter((option) => option.visible)
      .map((opt) => opt.gridIndex);
    const newLayouts = {};
    Object.keys(layouts).forEach((key) => {
      const layoutItems = initialLayouts[key];
      const newLayoutItems = layoutItems.filter((item) =>
        visibleIndexes.includes(item.i)
      );
      newLayouts[key] = newLayoutItems;
    });
    setLayouts(newLayouts);
  }, [cardOptions]);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.chartsRoot}>
          <div className={styles.charts}>
            {chartSeverity.map((data, index) => (
              <div className={styles.chart} key={index}>
                <Doughnut
                  data={data}
                  width={"100%"}
                  height={"100%"}
                  key={index}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    cutout: 60,
                  }}
                />
                <div className={styles.labelRoot}>
                  <div className={styles.label}>{data.label}</div>
                  <div className={styles.value}>{data.datasets[0].data[0]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.dropdown}>
          <label htmlFor="region">Region </label>
          <select
            className={styles.regionSelect}
            name="region"
            id="region"
            onChange={handleRegionChange}
          >
            {regions.map((item) => (
              <option
                value={item.pk}
                key={item.pk}
                selected={region && item.pk === region.pk}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <ResponsiveGridLayout
          className="layout"
          rowHeight={300}
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
          cols={{ lg: 3, md: 3, sm: 2, xs: 1 }}
          margin={[16, 16]}
        >
          {cardOptions
            .filter((card) => card.visible)
            .map((cardOption) => {
              const { title, gridIndex, renderCustom, ...others } = cardOption;
              if (renderCustom) {
                return <div key={gridIndex}>{renderCustom()}</div>;
              } else {
                return (
                  <div key={gridIndex}>
                    <DashboardCard title={title} {...others} />
                  </div>
                );
              }
            })}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default Dashboard;
