import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import api from "services/api";
import AuthService from "services/auth";
import LoadingIndicator from "components/LoadingIndicator";

import styles from "./styles.module.scss";

const formSchema = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const Login = () => {
  const submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const payload = {
      password: values.password,
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const key = emailRegex.test(values.email) ? "email" : "username";
    payload[key] = values.email;
    let res = await api.options("/api/v1/auth/login");
    debugger;
    try {
      const { data } = await api.post("/api/v1/auth/login", payload);
      AuthService.setAccessToken(data.access_token);
      AuthService.setUserData(data.user);
      window.location.reload();
    } catch (e) {
      console.log("login error", e);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <h2 className={styles.pageTitle}>Welcome to BASEC</h2>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={formSchema}
        onSubmit={submitForm}
      >
        {({ isSubmitting }) => (
          <Form className={styles.loginForm}>
            <div className={styles.title}>
              <h4>Log In</h4>
            </div>
            <div className={styles.inputWrapper}>
              <label htmlFor="email" className="input-label">
                Email
              </label>
              <Field name="email" type="text" className="text-input" />
              <ErrorMessage
                name="email"
                component="div"
                className="inputError"
              />
            </div>

            <div className={styles.inputWrapper}>
              <label htmlFor="password" className="input-label">
                Password
              </label>
              <Field name="password" type="password" className="text-input" />
              <ErrorMessage
                name="password"
                component="div"
                className="inputError"
              />
            </div>

            <div className={styles.btnWrapper}>
              <button
                type="submit"
                className={classNames(styles.loginBtn, "primary-btn")}
              >
                {isSubmitting ? <LoadingIndicator light /> : "Log In"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
