import React from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import { motion } from "framer-motion";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";

import { ReactComponent as AnalysisIcon } from "assets/icons/analysis.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as InventoryIcon } from "assets/icons/inventory.svg";
// import { ReactComponent as HelpIcon } from "assets/icons/help.svg";
import { ReactComponent as IdmIcon } from "assets/icons/idm.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";

import AuthService from "services/auth";

import styles from "./styles.module.scss";

const navMap = [
  {
    icon: DashboardIcon,
    text: "Dashboard",
    url: "/dashboard",
  },
  {
    icon: InventoryIcon,
    text: "Inventory",
    url: "/inventory",
  },
  {
    icon: UploadIcon,
    text: "Upload",
    url: "/upload",
  },
  {
    icon: AnalysisIcon,
    text: "Analysis",
    url: "/analysis",
  },
  {
    icon: SettingsIcon,
    text: "Settings",
    url: "/settings",
  },
  {
    icon: IdmIcon,
    text: "Idm",
    url: "/idm",
  },
  // {
  //   icon: HelpIcon,
  //   text: "Help",
  //   url: "/help",
  // },
];

const variants = {
  expand: {
    width: "2%",
  },
  default: {
    width: "20%",
  },
};

const variantsText = {
  expand: {
    opacity: 0,
  },
  default: {
    opacity: 1,
  },
};

const Sidebar = () => {
  const isNavExpanded = true;
  const location = useLocation();

  const userData = AuthService.getUserData();

  const renderNavItem = (item) => {
    const isItemActive = location.pathname === item.url;
    const Icon = item.icon;

    const navLinkClasses = cx(styles.navItem, {
      [styles.navItemActive]: isItemActive,
    });

    return (
      <Link to={item.url} className={navLinkClasses} key={item.url}>
        <div className={styles.navItemIconWrapper}>
          <div className={styles.innerIconWrapper}>
            <Icon />
          </div>
        </div>

        <div className={styles.navItemText}>{item.text}</div>
      </Link>
    );
  };

  return (
    <motion.div
      variants={variants}
      transition={{
        type: "tweet",
        duration: 0.2,
      }}
      className={styles.navigation}
      animate={isNavExpanded ? "default" : "expand"}
    >
      <motion.div
        variants={variantsText}
        transition={{
          type: "tweet",
          duration: 0.1,
        }}
        animate={isNavExpanded ? "default" : "expand"}
      >
        <div className={styles.banner}>
          <div className={styles.before} />
          <div className={styles.heading}>Basec</div>
        </div>
        <div className={styles.navItemsContainer}>
          <div className={styles.navIconsWrapper}>
            {navMap.map((item) => {
              return renderNavItem(item);
            })}
          </div>
          <div className={styles.userInfo}>
            <div className={styles.divider} />
            <div className={styles.userWrapper}>
              <div className={styles.userInfoInner}>
                {userData.photo ? (
                  <img src={userData.photo} className={styles.image} alt="" />
                ) : (
                  <div className={styles.iconWrapper}>
                    <AiOutlineUser />
                  </div>
                )}
                <div className={styles.userName}></div>
              </div>
              <button
                className={styles.logoutWrapper}
                onClick={AuthService.logout}
              >
                <MdOutlineLogout />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Sidebar;
