import React, { useState, useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { toast } from "react-toastify";

import AuthService from "services/auth";
import Modal from "components/Modal";
import api from "services/api";
import LoadingIndicator from "components/LoadingIndicator";

import styles from "./styles.module.scss";

const profileFormSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
});

const ProfileEdit = (props) => {
  const { showProfile, onClose } = props;
  const userData = AuthService.getUserData();

  const [photoFieldValue, setPhotoFieldValue] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);

  const submitProfileForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // Do something with the files
    let formData = new FormData();
    if (photoFieldValue) {
      formData.append("photo", photoFieldValue);
    }
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);

    try {
      const { data } = await api.patch("/api/v1/account/user", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      AuthService.setUserData(data);
      toast.success("Updated Profile Successfully!");
    } catch (e) {
      console.log("register error", e);
    }
    setSubmitting(false);
    onClose();
  };

  const closeModal = async () => {
    onClose();
  };

  const handleFile = (event) => {
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const file = event.target.files[0];
    if (!file.type.match(imageMimeType)) {
      return;
    }
    setPhotoFieldValue(event.currentTarget.files[0]);
  };

  useEffect(() => {
    let fileReader;
    let isCancel = false;
    if (photoFieldValue) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setPhotoURL(result);
        }
      };
      fileReader.readAsDataURL(photoFieldValue);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [photoFieldValue]);

  return (
    <>
      <Modal
        visible={showProfile}
        disableOutsideClose={true}
        close={() => closeModal()}
      >
        <div className={styles.close} onClick={closeModal}>
          X
        </div>
        <Formik
          initialValues={{
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
          }}
          validationSchema={profileFormSchema}
          onSubmit={submitProfileForm}
        >
          {({ isSubmitting }) => (
            <Form className={styles.profileEditForm}>
              <div className={styles.title}>
                <h4>Update Profile</h4>
              </div>
              <div className={styles.nameWrapper}>
                {userData.photo ? (
                  <div className={styles.inputWrapper}>
                    <div className={styles.image}>
                      {photoURL ? (
                        <img src={photoURL} alt="preview" />
                      ) : (
                        <img
                          src={userData.photo}
                          className={styles.image}
                          alt=""
                        />
                      )}
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept="image/*"
                        onChange={handleFile}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.inputWrapper}>
                    <div className={styles.iconWrapper}>
                      <div className={styles.image}>
                        {photoURL ? (
                          <img src={photoURL} alt="preview" />
                        ) : (
                          <AiOutlineUser />
                        )}
                        <input
                          id="file"
                          name="file"
                          type="file"
                          accept="image/*"
                          onChange={handleFile}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.inputWrapper}>
                  <label htmlFor="first_name" className="input-label">
                    First Name
                  </label>
                  <Field name="first_name" type="text" className="text-input" />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="inputError"
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="last_name" className="input-label">
                    Last Name
                  </label>
                  <Field name="last_name" type="text" className="text-input" />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="inputError"
                  />
                </div>
              </div>

              <div className={styles.inputWrapper}>
                <label htmlFor="email" className="input-label">
                  Email
                </label>
                <Field name="email" type="email" className="text-input" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="inputError"
                />
              </div>

              <div className={styles.btnWrapper}>
                <button type="submit" className={classNames("primary-btn")}>
                  {isSubmitting ? <LoadingIndicator light /> : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ProfileEdit;
