import React, { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";

import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";

import Sidebar from "../Sidebar";
import styles from "./styles.module.scss";

const MainLayout = () => {
  const location = useLocation();

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.body}>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
