import React, { useState } from "react";

import Uploads from "components/Uploads";
import FileUploader from "components/FileUploader";

import styles from "./styles.module.scss";

const Upload = () => {
  const [refresh, setRefresh] = useState(false);

  const onRefresh = () => {
    setRefresh(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.uploadWrapper}>
        <FileUploader onRefresh={onRefresh} />
      </div>
      <Uploads className={styles.tableWrapper} refresh={refresh} />
    </div>
  );
};

export default Upload;
