import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { debounce } from "lodash";
import api from "services/api";
import { GoSearch } from "react-icons/go";
import styles from "./styles.module.scss";
import LoadingIndicator from "components/LoadingIndicator";

const showOptions = [25, 50, 100];

const Inventory = (props) => {
  const { className } = props;

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [orderStr, setOrderStr] = useState("");

  // Pagination
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const columns = [
    {
      label: "Type",
      field: "type",
    },
    {
      label: "System Name",
      field: "systemName",
    },
    {
      label: "Total",
      field: "total",
    },
  ];

  const sortOptions = [
    {
      label: "Sort by",
      field: "",
    },
    {
      label: "Type",
      field: "type",
    },
    {
      label: "System Name",
      field: "systemName",
    },
  ];

  const handlePageClick = async (event) => {
    setPage(event.selected);
    setApiData(
      await getDataFromAPI(
        `/api/v1/systems?name=${searchStr}&order=${orderStr}&limit=${rowsPerPage}&offset=${
          rowsPerPage * event.selected
        }`
      )
    );
  };

  const getDataFromAPI = async (url) => {
    setLoading(true);
    let dataList = [];
    try {
      const res = await api.get(url);
      const count = res.data.count;
      setPageCount(
        Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1)
      );
      for (let result of res.data.results) {
        dataList.push({
          type: `${result.frcs}`,
          systemName: `${result.name}`,
          total: `${result.total}`,
        });
      }
    } catch (e) {
      console.log("register error", e);
    }
    setLoading(false);
    return dataList;
  };

  const handleSearchInput = debounce(async (e) => {
    const query = e.target.value;
    setSearchStr(query);
    setApiData(
      await getDataFromAPI(`/api/v1/systems?name=${query}&order=${orderStr}`)
    );
  }, 500);

  const handleOrderInput = async (e) => {
    const order = e.target.value;
    setOrderStr(order);
    setApiData(
      await getDataFromAPI(`/api/v1/systems?name=${searchStr}&order=${order}`)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI(`/api/v1/systems?limit=${rowsPerPage}`));
    };
    fetchData();
  }, [rowsPerPage]);

  const visibleRows = useMemo(() => {
    return loading ? [] : apiData || [];
  }, [page, rowsPerPage, apiData, loading]);

  return (
    <>
      {loading && <LoadingIndicator light pageLoading />}
      <div className={styles.tableWrapper}>
        <div className={styles.header}>
          <div className={styles.search}>
            <GoSearch />
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchInput}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.dropdownWrapper}>
            <div className="dropdown">
              <select
                onChange={handleOrderInput}
                className={styles.orderSelect}
              >
                {sortOptions.map((col) => (
                  <option value={col.field} key={col.field}>
                    {col.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className={classNames(styles.tableContainer, className)}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th
                    key={col.label}
                    style={{
                      textAlign: col.textAlign || "left",
                      width: col.width || "unset",
                    }}
                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {visibleRows.map((item, index) => (
                <tr key={index}>
                  {columns.map((col, i) => (
                    <td
                      key={i}
                      style={{
                        borderLeft:
                          i === 0 ? `8px solid ${item.color}` : "unset",
                        textAlign: col.textAlign || "left",
                        width: col.width || "unset",
                      }}
                    >
                      <span>{item[col.field]}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.footer}>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>
          <div className="dropdown">
            <span>Show: </span>
            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {showOptions.map((option) => (
                <option key={option} value={option}>
                  {option} rows
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inventory;
