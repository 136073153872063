import { createContext, useContext, useEffect, useState } from "react";

export const UserContext = createContext({});

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    // TODO
    setUser({});
    setLoading(false);
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
