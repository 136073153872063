import axios from "axios";
import { toast } from "react-toastify";
import AuthService from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.basec-staging.com",
});

api.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    if (AuthService.getAccessToken()) {
      newConfig.headers.Authorization = `JWT ${AuthService.getAccessToken()}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401 && AuthService.isLoggedIn()) {
      AuthService.logout();
    }
    if (error.response.status >= 400 && error.response.status < 500) {
      const errorData = error.response.data;
      let errorMsg = "Something went wrong!";
      if (errorData && typeof errorData === "object") {
        const keys = Object.keys(errorData);
        if (keys.includes("detail")) {
          errorMsg = errorData[keys[0]];
        } else if (keys.length) {
          const errorMsgs = errorData[keys[0]];
          if (errorMsgs?.length) {
            errorMsg = errorMsgs.join(", ");
          }
        }
      }
      toast.error(errorMsg);
    }
    return Promise.reject(error);
  }
);

export default api;
