import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "components/Modal";
import api from "services/api";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import AsyncSelect from "react-select/async";

const Invite = (props) => {
  const { className, region, showInvite, refresh, onClose } = props;

  const columns = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Region",
      field: "region",
    },
    {
      label: "Type",
      field: "type",
    },
    {
      label: "Action",
      field: "action",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);

  const [defaultinviteOptions, setDefaultinviteOptions] = useState([]);

  const [invite, setInvite] = useState(null);

  // Pagination
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#e3e9ef",
    }),
  };

  const onInviteBlur = () => {};

  const onInviteChange = (selectedInvite) => {
    setInvite(selectedInvite);
  };

  const fetchDefaultInviteOptions = () => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/v1/region/users?limit=1000`);
        setDefaultinviteOptions(
          res.data.results.map((item) => ({
            value: `${item.email}`,
            label: `${item.first_name} ${item.last_name}`,
          }))
        );
      } catch (e) {
        console.log("register error", e);
      }
    };
    fetchData();
  };

  const loadInviteOptions = async (inputText, callback) => {
    try {
      const res = await api.get(`/api/v1/region/users?name=${inputText}`);
      callback(
        res.data.results.map((item) => ({
          value: `${item.email}`,
          label: `${item.first_name} ${item.last_name}`,
        }))
      );
    } catch (e) {
      console.log("register error", e);
    }
  };

  const handlePageClick = async (event) => {
    setApiData(
      await getDataFromAPI(
        `/api/v1/region/${region}/invites?limit=${rowsPerPage}&offset=${
          rowsPerPage * event.selected
        }`
      )
    );
  };

  const removeInvite = async (event, invite) => {
    try {
      await api.delete(`/api/v1/region/${region}/invite/${invite.key}/remove`);
      toast.success("Invite was Removed!");
    } catch (e) {
      console.log("register error", e);
    }
    setApiData(await getDataFromAPI(`/api/v1/region/${region}/invites`));
  };

  const resendInvite = async (event, invite) => {
    try {
      await api.post(`/api/v1/region/${region}/invite/${invite.key}/resend`);
      toast.success("Invite was Resent!");
    } catch (e) {
      console.log("register error", e);
    }
  };

  const acceptInvite = async (event, invite) => {
    try {
      await api.post(
        `/api/v1/region/${region}/invite/request/${invite.key}/accept`
      );
      toast.success("Invite was Accepted!");
    } catch (e) {
      console.log("register error", e);
    }
    onClose(true);
  };

  const submitInviteForm = async (event) => {
    event.preventDefault();
    if (!invite) {
      return;
    }
    try {
      await api.post(`/api/v1/region/${region}/invite`, {
        invitee: invite.value,
      });
      toast.success("Invite sent Successfully!");
    } catch (e) {
      console.log("register error", e);
    }
    setInvite(null);
    setApiData(await getDataFromAPI(`/api/v1/region/${region}/invites`));
  };

  const getDataFromAPI = async (url) => {
    setLoading(true);
    let dataList = [];
    try {
      const res = await api.get(url);
      const count = res.data.count;
      setPageCount(
        Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1)
      );
      for (let result of res.data.results) {
        const row = {
          key: `${result.key}`,
          name: `${result.invitee.email}`,
          region: `${result.region?.name}`,
          type: `${result.type}`,
          action: "",
        };
        dataList.push(row);
      }
    } catch (e) {
      console.log("register error", e);
    }
    setLoading(false);
    return dataList;
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI(`/api/v1/region/${region}/invites`));
    };
    fetchData();
    fetchDefaultInviteOptions();
  }, [refresh]);

  const handleModelClose = () => {
    setInvite(null);
    onClose(false);
  };

  const visibleRows = useMemo(() => {
    return apiData || [];
  }, [apiData]);

  return (
    <>
      <Modal
        visible={showInvite}
        close={() => onClose(false)}
        disableOutsideClose={true}
        extraClass={styles.inviteWrapper}
      >
        <div className={styles.container}>
          <div className={styles.close} onClick={handleModelClose}>
            X
          </div>
          <form className={styles.inviteForm} onSubmit={submitInviteForm}>
            <div className={styles.title}>
              <h4>Invite User</h4>
            </div>
            <div className={styles.nameWrapper}>
              <div className={styles.inputWrapper}>
                <div className={styles.inputField}>
                  <label htmlFor="region" className="inputLabel">
                    Invite user to join your region
                  </label>
                  <div className={styles.inviteField}>
                    <AsyncSelect
                      className={styles.selectField}
                      value={invite}
                      styles={customStyles}
                      onChange={onInviteChange}
                      placeholder={""}
                      loadOptions={loadInviteOptions}
                      defaultOptions={defaultinviteOptions}
                      isClearable={true}
                      onBlur={onInviteBlur}
                      noOptionsMessage={() => null}
                    />
                    <button type="submit" className={"primary-btn"}>
                      Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className={styles.tableWrapper}>
            <div className={classNames(styles.tableContainer, className)}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    {columns.map((col) => (
                      <th
                        key={col.label}
                        style={{
                          textAlign: col.textAlign || "left",
                          width: col.width || "unset",
                        }}
                      >
                        {col.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {visibleRows.map((item, index) => (
                    <tr key={index}>
                      {columns.map((col, i) => (
                        <td
                          key={i}
                          style={{
                            textAlign: col.textAlign || "left",
                            width: col.width || "unset",
                          }}
                        >
                          {col.field === "action" && (
                            <div className={styles.actions}>
                              {item.type === "invite" && (
                                <button
                                  className={"primary-btn-sm"}
                                  onClick={(e) => resendInvite(e, item)}
                                >
                                  Resend
                                </button>
                              )}
                              {item.type === "request" && (
                                <button
                                  className={"primary-btn-sm"}
                                  onClick={(e) => acceptInvite(e, item)}
                                >
                                  Accept
                                </button>
                              )}
                              <div
                                className={styles.remove}
                                onClick={(e) => removeInvite(e, item)}
                              >
                                <AiOutlineDelete />
                              </div>
                            </div>
                          )}
                          <span>{item[col.field]}</span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={styles.footer}>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="pagination"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Invite;
