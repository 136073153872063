import React, { useRef } from "react";

import useOutsideAlerter from "hooks/useOutsideAlerter";

import styles from "./styles.module.scss";

const Modal = (props) => {
  const {
    children,
    visible,
    fullscreen,
    close,
    extraClass,
    disableOutsideClose,
  } = props;

  const bodyRef = useRef();

  useOutsideAlerter(bodyRef, disableOutsideClose ? null : close);

  return (
    <div
      className={`${styles.wrapper} ${extraClass} ${visible ? "shown" : ""}`}
    >
      <div
        className={`${styles.body} ${fullscreen ? "bodyFull" : ""}`}
        ref={bodyRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
