import React, { useState, useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { toast } from "react-toastify";

import AuthService from "services/auth";
import Modal from "components/Modal";
import api from "services/api";
import LoadingIndicator from "components/LoadingIndicator";

import styles from "./styles.module.scss";

const passwordFormSchema = Yup.object({
  current_password: Yup.string().required("Required"),
  new_password1: Yup.string().required("Required"),
  new_password2: Yup.string().oneOf(
    [Yup.ref("new_password1"), null],
    "Passwords must match"
  ),
});

const Password = (props) => {
  const { showPassword, onClose } = props;

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    debugger;
    // Do something with the files
    try {
      const { data } = await api.post(
        "/api/v1/account/password/change",
        values
      );
      toast.success("Updated Profile Successfully!");
    } catch (e) {
      console.log("register error", e);
    }
    resetForm({ values: "" });
    setSubmitting(false);
    onClose();
  };

  const closeModal = async () => {
    onClose();
  };

  return (
    <>
      <Modal
        visible={showPassword}
        disableOutsideClose={true}
        close={() => closeModal()}
      >
        <div className={styles.close} onClick={closeModal}>
          X
        </div>
        <Formik
          initialValues={{
            current_password: "",
            new_password1: "",
            new_password2: "",
          }}
          validationSchema={passwordFormSchema}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form className={styles.profileEditForm}>
              <div className={styles.title}>
                <h4>Update Password</h4>
              </div>
              <div className={styles.nameWrapper}>
                <div className={styles.inputWrapper}>
                  <label htmlFor="current_password" className="input-label">
                    Current Password
                  </label>
                  <Field
                    name="current_password"
                    type="password"
                    className="text-input"
                  />
                  <ErrorMessage
                    name="current_password"
                    component="div"
                    className="inputError"
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="new_password1" className="input-label">
                    New Password
                  </label>
                  <Field
                    name="new_password1"
                    type="password"
                    className="text-input"
                  />
                  <ErrorMessage
                    name="new_password1"
                    component="div"
                    className="inputError"
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="new_password2" className="input-label">
                    New Password (again)
                  </label>
                  <Field
                    name="new_password2"
                    type="password"
                    className="text-input"
                  />
                  <ErrorMessage
                    name="new_password2"
                    component="div"
                    className="inputError"
                  />
                </div>
              </div>

              <div className={styles.btnWrapper}>
                <button type="submit" className={classNames("primary-btn")}>
                  {isSubmitting ? (
                    <LoadingIndicator light />
                  ) : (
                    "Change Password"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Password;
