import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import Modal from "components/Modal";
import api from "services/api";

import styles from "./styles.module.scss";
import LoadingIndicator from "components/LoadingIndicator";

const showOptions = [25, 50, 100];

const Findings = (props) => {
  const { className, showFinding, analysis, onClose } = props;
  const columns = [
    {
      label: "Title",
      field: "title",
    },
    {
      label: "Risk Rating",
      field: "risk_rating",
    },
    {
      label: "Description",
      field: "description",
    },
  ];

  const [showFindingsModal, setShowFindingsModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);

  // Pagination
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const closeFindingsModal = async () => {
    setShowFindingsModal(false);
    onClose();
  };

  const generatePDFReport = async () => {
    try {
      const res = await api.get(`/api/v1/analysis/${analysis.pk}/findings/pdf`);
      download(res.data, "analysis-finding-report.pdf", "application/pdf");
    } catch (e) {
      console.log("register error", e);
    }
  };

  const download = (data, filename, type) => {
    let a = document.createElement("a");
    const file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      // Others
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  const handlePageClick = async (event) => {
    setPage(event.selected);
    setApiData(
      await getDataFromAPI(
        `/api/v1/analysis/${analysis.pk}/findings?limit=${rowsPerPage}&offset=${
          rowsPerPage * page
        }`
      )
    );
  };

  const getDataFromAPI = async (url) => {
    setLoading(true);
    try {
      const res = await api.get(url);
      const count = res.data.count;
      setPageCount(
        Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1)
      );
      setLoading(false);
      return res.data.results;
    } catch (e) {
      console.log("register error", e);
    }
    return [];
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(
        await getDataFromAPI(`/api/v1/analysis/${analysis.pk}/findings`)
      );
    };
    if (showFinding && analysis) {
      fetchData();
      setShowFindingsModal(true);
    }
  }, [analysis, page, pageCount, rowsPerPage]);

  const visibleRows = useMemo(() => {
    return loading ? [] : apiData || [];
  }, [page, rowsPerPage, apiData, loading]);

  return (
    <>
      <Modal
        fullscreen
        visible={showFindingsModal}
        disableOutsideClose={true}
        close={() => closeFindingsModal()}
        extraClass={styles.findingsWrapper}
      >
        {loading && <LoadingIndicator light pageLoading />}
        <div className={styles.tableWrapper}>
          <div className={styles.header}>
            <h3>Findings</h3>
            <div className={styles.close} onClick={generatePDFReport}>
              View PDF Report
            </div>
            <div className={styles.close} onClick={closeFindingsModal}>
              X
            </div>
          </div>
          <div className={classNames(styles.tableContainer, className)}>
            <table className={styles.table}>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.label}
                      style={{
                        textAlign: col.textAlign || "left",
                        width: col.width || "unset",
                      }}
                    >
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {visibleRows.map((item, index) => (
                  <tr key={index}>
                    {columns.map((col, i) => (
                      <td
                        key={i}
                        style={{
                          borderLeft:
                            i === 0 ? `8px solid ${item.color}` : "unset",
                          textAlign: col.textAlign || "left",
                          width: col.width || "unset",
                        }}
                      >
                        <span>{item[col.field]}</span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={styles.footer}>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination"
            />

            <div className="dropdown">
              <span>Show: </span>
              <select
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                {showOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} rows
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Findings;
