import cookie from "js-cookie";
import moment from "moment";

class AuthService {
  getOptions() {
    return { expires: moment().add(30, "days").toDate() };
  }

  getAccessToken() {
    return cookie.get("access_token");
  }

  setAccessToken(accessToken) {
    cookie.set("access_token", accessToken, this.getOptions());
  }

  getUserData() {
    const userData = cookie.get("user_data");
    if (!userData) return null;
    return JSON.parse(userData);
  }

  setUserData(userData) {
    cookie.set("user_data", JSON.stringify(userData), this.getOptions());
  }

  getUserRole() {
    const userData = this.getUserData();
    return userData?.role;
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  logout() {
    cookie.remove("access_token");
    cookie.remove("user_data");
    window.location.reload();
  }
}

export default new AuthService();
