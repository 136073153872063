import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import AuthService from "services/auth";
import { toast } from "react-toastify";
import api from "services/api";

import styles from "./styles.module.scss";

const FileUploader = (props) => {
  const { onRefresh } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file, index) => {
      // Do something with the files
      let formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      try {
        const res = await api.post("/api/v1/analysis/create", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.status == 201) {
          toast.success("Configuration File Uploaded");
        }
        if (acceptedFiles.length === index + 1) {
          onRefresh();
        }
      } catch (e) {
        console.log("invite error", e);
      }
    });
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } =
    useDropzone({
      onDrop,
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
    });

  return (
    <div className={styles.container}>
      <div
        {...getRootProps({
          className: isDragActive ? "dropzone active" : "dropzone",
        })}
      >
        <input {...getInputProps()} />
        <button type="button" className={styles.btn} onClick={open}>
          Upload
        </button>
        <p>Drop files here to upload</p>
      </div>
    </div>
  );
};

export default FileUploader;
