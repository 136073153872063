import { Outlet } from "react-router-dom";
import styles from "./styles.module.scss";

const AuthLayout = () => (
  <div className={styles.wrapper}>
    <Outlet />
  </div>
);

export default AuthLayout;
