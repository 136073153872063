import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import moment from "moment";
import api from "services/api";
import styles from "./styles.module.scss";
import LoadingIndicator from "components/LoadingIndicator";
import Device from "components/Device";

const showOptions = [25, 50, 100];

const Uploads = (props) => {
  const { className, refresh } = props;

  const [newDevice, setNewDevice] = useState(false);
  const [showDevice, setShowDevice] = useState(false);
  const [analysis, setAnalysis] = useState(null);

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);

  // Pagination
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const columns = [
    {
      label: "Device",
      field: "device",
    },
    {
      label: "Manufacturer",
      field: "vender",
    },
    {
      label: "Status",
      field: "status",
    },
    {
      label: "Uploaded By",
      field: "uploadedBy",
    },
    {
      label: "Uploaded At",
      field: "uploadedAt",
    },
  ];

  const handlePageClick = async (event) => {
    setPage(event.selected);
    setApiData(
      await getDataFromAPI(
        `/api/v1/analysis/uploads?limit=${rowsPerPage}&offset=${
          rowsPerPage * event.selected
        }`
      )
    );
  };

  const onDeviceClose = (reload) => {
    setShowDevice(false);
    setAnalysis(null);
    const fetchData = async () => {
      setApiData(await getDataFromAPI("/api/v1/analysis/uploads"));
    };
    if (reload === true) {
      fetchData();
    }
  };

  const getDataFromAPI = async (url) => {
    setLoading(true);
    let dataList = [];
    try {
      const res = await api.get(url);
      const count = res.data.count;
      setPageCount(
        Math.trunc(count / rowsPerPage) + (count % rowsPerPage === 0 ? 0 : 1)
      );
      for (let result of res.data.results) {
        dataList.push({
          pk: `${result.pk}`,
          vender: `${result.vender}`,
          status: `${result.status}`,
          uploadedAt: moment(Date.parse(`${result.uploaded_at}`)).format(
            "L h:mm a"
          ),
          device: result.device,
          uploadedBy: `${result.user.first_name} ${result.user.last_name}`,
        });
      }
    } catch (e) {
      console.log("register error", e);
    }
    setLoading(false);
    return dataList;
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI("/api/v1/analysis/uploads"));
    };
    if (refresh === true) {
      fetchData();
    }
  }, [refresh]);

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI("/api/v1/analysis/uploads"));
    };
    fetchData();
  }, [page, pageCount, refresh, rowsPerPage]);

  const visibleRows = useMemo(() => {
    return loading ? [] : apiData || [];
  }, [page, rowsPerPage, apiData, loading]);

  return (
    <>
      {loading && <LoadingIndicator light pageLoading />}
      <div className={styles.tableWrapper}>
        <div className={classNames(styles.tableContainer, className)}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th
                    key={col.label}
                    style={{
                      textAlign: col.textAlign || "left",
                      width: col.width || "unset",
                    }}
                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {visibleRows.map((item, index) => (
                <tr key={index}>
                  {columns.map((col, i) => (
                    <td
                      key={i}
                      style={{
                        borderLeft:
                          i === 0 ? `8px solid ${item.color}` : "unset",
                        textAlign: col.textAlign || "left",
                        width: col.width || "unset",
                      }}
                    >
                      {col.field === "device" ? (
                        <button
                          className={styles.deviceBtn}
                          onClick={() => {
                            setAnalysis(item);
                            setShowDevice(true);
                            setNewDevice(item[col.field] ? false : true);
                          }}
                        >
                          {item[col.field] ? (
                            <span>Edit</span>
                          ) : (
                            <span>Add</span>
                          )}
                        </button>
                      ) : (
                        <span>{item[col.field]}</span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Device
          showDevice={showDevice}
          analysis={analysis}
          onClose={onDeviceClose}
          create={newDevice}
        />

        <div className={styles.footer}>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>

          <div className="dropdown">
            <span>Show: </span>
            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {showOptions.map((option) => (
                <option key={option} value={option}>
                  {option} rows
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uploads;
