import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import api from "services/api";
import AuthService from "services/auth";
import LoadingIndicator from "components/LoadingIndicator";

import styles from "./styles.module.scss";

const formSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  password1: Yup.string().required("Required"),
  // password2: Yup.string().required('Required')
});

const Register = () => {
  const submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { data } = await api.post("/api/v1/account/register", values);
      AuthService.setAccessToken(data.access_token);
      AuthService.setUserData(data.user);
      window.location.reload();
    } catch (e) {
      console.log("register error", e);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <h2 className={styles.pageTitle}>Welcome to BASEC</h2>

      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          username: "",
          email: "",
          password1: "",
          password2: "",
        }}
        validationSchema={formSchema}
        onSubmit={submitForm}
      >
        {({ isSubmitting }) => (
          <Form className={styles.loginForm}>
            <div className={styles.title}>
              <h4>Register</h4>
            </div>
            <div className={styles.nameWrapper}>
              <div className={styles.inputWrapper}>
                <label htmlFor="first_name" className="input-label">
                  First Name
                </label>
                <Field name="first_name" type="text" className="text-input" />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="inputError"
                />
              </div>
              <div className={styles.inputWrapper}>
                <label htmlFor="last_name" className="input-label">
                  Last Name
                </label>
                <Field name="last_name" type="text" className="text-input" />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="inputError"
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <label htmlFor="username" className="input-label">
                Username
              </label>
              <Field name="username" type="text" className="text-input" />
              <ErrorMessage
                name="username"
                component="div"
                className="inputError"
              />
            </div>

            <div className={styles.inputWrapper}>
              <label htmlFor="email" className="input-label">
                Email
              </label>
              <Field name="email" type="email" className="text-input" />
              <ErrorMessage
                name="email"
                component="div"
                className="inputError"
              />
            </div>

            <div className={styles.inputWrapper}>
              <label htmlFor="password1" className="input-label">
                Password
              </label>
              <Field name="password1" type="password" className="text-input" />
              <ErrorMessage
                name="password1"
                component="div"
                className="inputError"
              />
            </div>

            {/* <div className={styles.inputWrapper}>
              <label htmlFor="password2" className="input-label">
                Confirm Password
              </label>
              <Field name="password2" type="password" className="text-input" />
              <ErrorMessage
                name="password2"
                component="div"
                className="inputError"
              />
            </div> */}

            <div className={styles.btnWrapper}>
              <button
                type="submit"
                disabled={isSubmitting}
                className={classNames(styles.loginBtn, "primary-btn")}
              >
                {isSubmitting ? <LoadingIndicator light /> : "Register"}
              </button>
              <div className={styles.signupWrapper}>
                <span>Already have an account?</span>
                <Link to="/auth/login">
                  <button type="submit" className="underline-btn">
                    Login here.
                  </button>
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
