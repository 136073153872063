import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";

import api from "services/api";

const Settings = () => {
  const [apiData, setApiData] = useState([]);

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);

  const saveRule = async (item) => {
    const payload = [{ pk: item.pk, is_checked: true }];
    try {
      const res = await api.post("/api/v1/analysis/filter", payload);
    } catch (e) {
      console.log("register error", e);
    }
    return;
  };

  const saveRules = async (items, checked) => {
    let payload = [];
    for (let i = 0; i < items.length; i++) {
      payload.push({
        pk: items[i].pk,
        is_checked: checked,
      });
    }
    try {
      const res = await api.post("/api/v1/analysis/filter", payload);
    } catch (e) {
      console.log("register error", e);
    }
    return;
  };

  const getDataFromAPI = async (url) => {
    try {
      const res = await api.get(url);
      const count = res.data.count;
      return res.data.results;
    } catch (e) {
      console.log("register error", e);
    }
    return [];
  };

  const checkAll = () => {
    setApiData(
      apiData.map((item) => ({
        ...item,
        is_checked: true,
      }))
    );
    const storeData = async (items) => await saveRules(items, true);
    storeData(apiData);
  };

  const uncheckAll = () => {
    setApiData(
      apiData.map((item) => ({
        ...item,
        is_checked: false,
      }))
    );
    const storeData = async (items) => await saveRules(items, false);
    storeData(apiData);
  };

  const handleCheckboxClick = (index, is_checked) => {
    const storeData = async (item) => await saveRule(item);
    setApiData(
      apiData.map((item, i) => {
        if (index === i) {
          storeData(item);
          return {
            ...item,
            is_checked: is_checked,
          };
        } else {
          return item;
        }
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setApiData(await getDataFromAPI("/api/v1/analysis/rules?limit=1000"));
    };
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>Ignore these findings</span>
        <div className={styles.right}>
          <button className="underline-btn" onClick={checkAll}>
            Check all
          </button>
          <button className="underline-btn" onClick={uncheckAll}>
            Uncheck all
          </button>
        </div>
      </div>
      <div className={styles.body}>
        {apiData.map((rule, index) => (
          <div key={rule.label} className={styles.check}>
            <input
              type="checkbox"
              id={rule.title}
              name={rule.title}
              checked={rule.is_checked}
              onChange={(e) => handleCheckboxClick(index, e.target.checked)}
            />
            <label htmlFor={rule.label}>
              {rule.vender} - {rule.title}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
