import React, { useState, useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import AuthService from "services/auth";
import ProfileEdit from "components/ProfileEdit";
import Password from "components/Password";
import Member from "components/Member";
import styles from "./styles.module.scss";
import Invite from "components/Invite";
import InviteRequest from "components/InviteRequest";

const Idm = () => {
  const [refreshMembers, setRefreshMembers] = useState(false);
  const [refreshInvites, setRefreshInvites] = useState(false);
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showInviteRequestModal, setShowInviteRequestModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [userData, setUserData] = useState({});

  const closeProfileEditModal = () => {
    setShowProfileEditModal(false);
  };

  const closeInviteModal = (refresh) => {
    setShowInviteModal(false);
    setRefreshMembers(Math.random());
  };

  const closeInviteRequestModal = () => {
    setShowInviteRequestModal(false);
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  useEffect(() => {
    setUserData(AuthService.getUserData());
  }, [showProfileEditModal]);

  return (
    <div className={styles.container}>
      <div className={`${styles.title} ${styles.titleWrapper}`}>
        <span>My Profile</span>
        {userData?.role === "admin" && userData?.region && (
          <div className={styles.inviteWrapper}>
            <button
              className={"primary-btn"}
              onClick={() => {
                setShowInviteModal(true);
                setRefreshInvites(Math.random());
              }}
            >
              <span>Invites</span>
            </button>
            <Invite
              showInvite={showInviteModal}
              onClose={closeInviteModal}
              region={userData.region.pk}
              refresh={refreshInvites}
            />
          </div>
        )}
        {userData?.role === "normal" && !userData.region && (
          <div className={styles.inviteWrapper}>
            <button
              className={"primary-btn"}
              onClick={() => setShowInviteRequestModal(true)}
            >
              <span>Request Invite</span>
            </button>
            <InviteRequest
              showInvite={showInviteRequestModal}
              onClose={closeInviteRequestModal}
            />
          </div>
        )}
      </div>
      <div className={styles.profileCard}>
        {userData.photo ? (
          <img src={userData.photo} className={styles.image} alt="" />
        ) : (
          <div className={styles.iconWrapper}>
            <AiOutlineUser />
          </div>
        )}
        <div className={styles.info}>
          <div className={styles.item}>
            <div className={styles.label}>Full Name</div>
            <div className={styles.value}>
              {userData.first_name} {userData.last_name}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Email</div>
            <div className={styles.value}>{userData.email}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Region</div>
            <div className={styles.value}>{userData.region?.name || ""}</div>
          </div>
        </div>
        <div className={styles.btns}>
          <button
            className={styles.iconBtn}
            onClick={() => setShowProfileEditModal(true)}
          >
            <AiFillEdit />
          </button>
          <button
            className="underline-btn"
            onClick={(e) => setShowPasswordModal(true)}
          >
            Change password
          </button>
          <Password
            showPassword={showPasswordModal}
            onClose={closePasswordModal}
          />
        </div>
      </div>

      <ProfileEdit
        onClose={closeProfileEditModal}
        showProfile={showProfileEditModal}
      />

      {/*{userData?.role === "normal" && (
        <>
          <div className={styles.title}>Regions</div>
          <div className={styles.regionsTable}>
            <Region className={styles.table} apiUrl="/api/v1/region/search" />
          </div>
        </>
      )}*/}

      {userData?.role === "admin" && (
        <>
          <Member
            className={styles.table}
            region={`${userData.region.pk}`}
            refresh={refreshMembers}
          />
        </>
      )}
    </div>
  );
};

export default Idm;
