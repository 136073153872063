import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Routes from "pages/Routes";
import Providers from "./Providers";

import "react-toastify/dist/ReactToastify.css";
import "./app.scss";

const App = () => {
  return (
    <Providers>
      <BrowserRouter>
        <Routes />
        <ToastContainer />
      </BrowserRouter>
    </Providers>
  );
};

export default App;
